import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

//#region Estilos
const useStyles = makeStyles(() => ({
    botonWhatsapp: {
        display:"block",
        width:"60px",
        height:"55px",
        color:"#fff",
        position: "fixed",
        right:"20px",
        bottom:"40px",
        borderRadius:"5%",
        lineHeight:"55px",
        textAlign:"center",
        zIndex:"999",
        background: "transparent"
    },
  }));
//#endregion Estilos

const BtnWhatsapp = () => {

    const classes = useStyles();
    const handleRefWhatsapp = () => {
        const href = `https://api.whatsapp.com/send?phone=554187861951`;
        window.open(href, '_blank');
    };
    return (
        <>
            <body className={classes.botonWhatsapp}>
                <IconButton
                    aria-label="toggle visibility"
                    style={{ fontSize: '50px', background:'#ebebeb' }} 
                >
                    <WhatsAppIcon
                        onClick={() => handleRefWhatsapp()}
                        style={{ fontSize: 'inherit', color:'#00A884' }} 
                    />
                </IconButton>
            </body>
        </>
    );
    
};

export default BtnWhatsapp;