import { connect } from "react-redux";
import { Box, Paper, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, TablePagination, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import RateReview from '@mui/icons-material/RateReview';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import IColumnData from "../../interface/IColumnData";
import { Alert, Stack } from "@mui/material";
import './table.css';

const useStyles = makeStyles({
    table: {
        minWidth: 750,
    },
    paper: {
        width: '100%', 
        mb: 2 
    }
});

interface ITableCustomProps {
    page:number;
    onSelectedPage: (page: number)=> void;
    rowsPerPage:number;
    onSelectedRowPerPage: (rowsPerPage: number)=> void;
    items: any[];
    length: number;
    getItems: (page: number, rowsPerPage: number)=> void;
    columns: IColumnData[]; 
    handleDownload?: (item: any)=> void;
    handleNuevoReclamo?: (item: any)=> void;
    loading?: boolean;
    handleVerTracking?: (pedidoId:string)=> void;
}

const TableCustom = (props: ITableCustomProps) => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(true);
    const handleChangePage = (event: unknown, newPage: number) => {
        props.onSelectedPage(newPage);
        props.getItems(newPage, props.rowsPerPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onSelectedRowPerPage(parseInt(event.target.value, 10));
        props.onSelectedPage(0);
        props.getItems(0, parseInt(event.target.value, 10));
    };

    useEffect(() => {
        if(props.items) {
            setLoading(false);
        }
    }, [props.items]);


    return (
        <Box sx={{ width: '100%' }}>
            <Paper className={classes.paper}>
                <TableContainer>
                    {loading && 
                        <Box sx={{ display: 'flex',  justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    }     
                    { props.items?.length <= 0 && 
                        <Stack sx={{ width: '100%', margin: '2vh' }} spacing={2}>
                            <Alert severity="info">No tiene documentos asociados.</Alert>
                        </Stack>
                    }   
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <TableHead className="backgroundColor-grey">
                            <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    className={classes.table}
                                >
                                {props.columns.map((column) => (
                                    <TableCell className="fontWeight-bold" align="center">{column.label}</TableCell> 
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            props.items?.map((row: any)=> (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    className={classes.table}
                                >
                                    {props.columns.map((column) => (
                                        column.boolean && row[column.dataKey] ? 
                                        <TableCell align="center">
                                            <IconButton
                                                aria-label="toggle visibility"
                                                style={{ fontSize: '25px' }} // Ajusta el tamaño del icono aquí
                                            >
                                                {props.loading ?
                                                    <Box sx={{ display: 'flex',  justifyContent: 'center' }}>
                                                        <CircularProgress />
                                                    </Box>
                                                    : column.dataKey==='tienePdf' &&props.handleDownload ?
                                                        <DownloadForOfflineIcon
                                                            onClick={() => props.handleDownload!(row)}
                                                            style={{ fontSize: 'inherit' }}
                                                        />
                                                    : column.dataKey==='puedeCargarReclamo' && props.handleNuevoReclamo ?
                                                        <RateReview
                                                            onClick={() => props.handleNuevoReclamo!(row)}
                                                            style={{ fontSize: 'inherit' }} 
                                                        />
                                                    : 
                                                    column.dataKey==='puedeHacerSeguimiento' && props.handleVerTracking ?
                                                        <LocalShippingIcon
                                                            onClick={() => props.handleVerTracking!(row)}
                                                            style={{ fontSize: 'inherit' }} 
                                                        />
                                                    : ''
                                                }   
                                            </IconButton>
                                        </TableCell> 
                                        : <TableCell align="center">{row[column.dataKey]}</TableCell> 
                                    ))}
                                </TableRow>
                        ))}                                            
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    className="backgroundColor-grey-header"
                    count={props.length}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};
export default connect()(TableCustom);
