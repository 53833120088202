import React from "react";
import Carousel from "react-bootstrap/Carousel";
import CARROUSEL_1 from "../assets/images/V2-CARROUSEL_1.jpg";
import CARROUSEL_2 from "../assets/images/V2-CARROUSEL_2.jpg";
import CARROUSEL_3 from "../assets/images/V2-CARROUSEL_3.jpg";
import CARROUSEL_4 from "../assets/images/V2-CARROUSEL_4.jpg";
import CARROUSEL_5 from "../assets/images/V2-CARROUSEL_5.jpg";

function ImagenCabeceraCarousel() {
  const images = [
    { src: CARROUSEL_1, alt: "First slide" },
    { src: CARROUSEL_2, alt: "Second slide" },
    { src: CARROUSEL_3, alt: "Third slide" },
    { src: CARROUSEL_4, alt: "Fourth slide" },
    { src: CARROUSEL_5, alt: "Fifth slide" },
  ];

  return (
    <Carousel fade>
      {images.map((image) => (
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100"
            src={image.src}
            alt={image.alt}
            style={{ width: "100%" }}
          />
          <Carousel.Caption>
            <h1
              style={{
                fontWeight: "bold",
                display: "flex",
                marginLeft: "-14%",
                fontSize: "55px",
              }}
            >
              {/* GESTIONÁ TUS PEDIDOS */}
            </h1>
            <h3
              style={{
                fontSize: "35px",
                display: "flex",
                marginLeft: "-13.2%",
              }}
            >
              {/* DONDE Y CUANDO VOS LO ELIJAS. */}
            </h3>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ImagenCabeceraCarousel;
