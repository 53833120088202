import React, { useEffect } from 'react';
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { requestResetPassword } from '../../actions/auth';
import * as Yup from "yup";
import LockIcon from '@mui/icons-material/Lock';
import rutas from '../../routes/constants/routes';

const ResetPassword = (props) => {

  const docType = props.docType;
  const history = useHistory();
  
  const formik = useFormik({
      initialValues: {
        identificationType: 1,
        identification: "",
        email: ""
      },
      validationSchema: Yup.object({
        identificationType: Yup.number().required("Por favor, Insira uma opção"),
        identification: Yup.string().required("Por favor, insira um número válido"),
        email: Yup.string().required("E-mail é obrigatório")
      }),
        onSubmit: (data) => {
            props.requestResetPassword(data);
        }
  })

  return (
    <>
      <div>
        <div className="d-grid gap-2 col-12 col-md-5 mx-auto" style={{paddingTop:"2rem", paddingBottom:"4rem"}}> 

          <div className="container">
            <div className="d-flex flex-row bd-highlight" style={{justifyContent:"center"}}>
              <div className="mb-3" >
              <div style={{height: '3.438rem', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"3rem" }}>
                        <div style={{width: "45px", height: "45px", background: "#E3000B", borderRadius: "100px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <LockIcon style={{fill: "white"}}></LockIcon>
                        </div>
                    </div>
                    <h5 className="text-center" style={{marginTop:"1rem",marginBottom:"1.875rem"}}>Recuperar senha</h5>
              </div>
            </div>
          </div>

          <form className="container" style={{marginBottom:"2.5rem"}} onSubmit={formik.handleSubmit}>
                <div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label" style={{color:"gray",fontWeight:600}}>Email</label>
                      <input type="email" onChange={formik.handleChange} error={formik.errors.email} name="email" id="email" 
                          className="form-control" style={{borderColor:"black",padding:"0.625rem"}}></input>
                      {formik.touched.email && formik.errors.email ? (
                              <div className='text-danger'>{formik.errors.email}</div>
                              ): null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="identification" className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem", clear: 'both', float:'left'}}>CNPJ</label>
                      <input name="identification" className="form-control" id="identification" style={{borderColor:"black", height: "2.5rem"}} onChange={formik.handleChange} error={formik.errors.identification}></input>
                      {formik.touched.identification && formik.errors.identification ? (
                          <div className='text-danger'>{formik.errors.identification}</div>
                      ): null}
                  </div>
                <div style={{maxWidth:"100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className={`btn btn-danger ${props.loading ? "disabled" : ""}`} style={{backgroundColor: "#E3000B", padding: "0.500rem 0.625rem", margin:"1rem 0rem", width:"100%"}} type="submit">Recuperar senha</button>
                </div>
                {
                  props?.successPasswordReset ?
                  <div class="alert alert-info" role="alert">
                      Verifique sua caixa de entrada de e-mail para recuperar sua senha.
                  </div>
                :
                  ""
                }
                {
                  props?.errorPasswordReset ?
                  <div class="alert alert-danger" role="alert">
                      { props.error.response.data.message }
                  </div>
                :
                  ""
                }
                  
            </div>
          </form>
          
        </div>
      </div>
    </>
  );
}

export default connect(
  state => ({
    docType: state.params.docType,
    successPasswordReset: state.auth.successPasswordReset,
    loading: state.auth.loading,
    errorPasswordReset: state.auth.errorPasswordReset,
    error: state.auth.error
  }),
  dispatch => ({
    requestResetPassword: (data) => dispatch(requestResetPassword(data))
  })
)(ResetPassword);