import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Pedido from "./pedido";
import {
  requestPresupuestos,
  requestPresupuestosSearch,
  requestFetchClientes,
} from "../../actions/presupuesto";
import { requestVendedores } from "../../actions/vendedor";
import Dolar from "../../routes/dolar";
import colores from "../../shared/constants/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Container,
  FormControl,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MyUserService from "../../services/myUser";

const MisPresupuestos = (props) => {
  const dispatch = useDispatch();
  const myUser = MyUserService.getMyUser();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [nroOperacion, setNroOperacion] = useState("");
  const [presupuesto, setPresupuesto] = useState("");
  const [pedido, setPedido] = useState("");
  const [clienteSelected, setClienteSelected] = useState(null);
  const [errorUI, seterrorUI] = useState({
    category: "",
    date: "",
  });

  useEffect(() => {
    dispatch(requestPresupuestos(myUser.clienteId));
    // if (localStorage?.tipoRol === 2) {
    //   dispatch(requestFetchClientes(local.sellerId));
    // }
    // if (localStorage?.tipoRol === 1) {
    //   dispatch(requestVendedores());
    // }
  }, []);

  useEffect(() => {
    if (props.VenClientes != null && props.VenClientes.length > 0) {
      setClienteSelected(props.VenClientes);
    } else {
      setClienteSelected(null);
    }
  }, [props.VenClientes]);

  const [filtro, setFiltro] = useState({
    clienteId: myUser.clienteId,
    nroOperacion: null,
    presupuesto: null,
    pedido: null,
    desde: null,
    hasta: null,
    vendedorId: null,
    fecha: null,
  });

  var vendedorId = "";

  const handleDateFromChange = (newDate) => {
    setFiltro({
      ...filtro,
      desde: newDate,
    });
    setDateFrom(newDate);
  };
  const handleDateToChange = (newDate) => {
    setFiltro({
      ...filtro,
      hasta: newDate,
    });
    setDateTo(newDate);
  };

  const handleNroOperacionToChange = (event) => {
    handleInputChange(event);
    setNroOperacion(event.target.value);
  };
  const handlePresupuestoToChange = (event) => {
    handleInputChange(event);
    setPresupuesto(event.target.value);
  };
  const handlePedidoToChange = (event) => {
    handleInputChange(event);
    setPedido(event.target.value);
  };

  const handleInputChange = (event) => {
    setFiltro({
      ...filtro,
      [event.target.name]: event.target.value,
    });
  };

  const handleClean = () => {
    setDateTo(null);
    setDateFrom(null);
    setNroOperacion("");
    setPresupuesto("");
    setPedido("");
    setFiltro({
      clienteId: myUser.clienteId,
      nroOperacion: "",
      presupuesto: "",
      pedido: "",
      desde: null,
      hasta: null,
    });
    dispatch(requestPresupuestosSearch(filtro));
  };

  const handleInputChangeVendedor = (event) => {
    if (event.target.value === "Selecciona...") {
      setClienteSelected(null);
      setFiltro({
        ...filtro,
        [event.target.name]: null,
        ["clienteId"]: null,
      });
    } else {
      vendedorId = event.target.value;
      dispatch(requestFetchClientes(vendedorId));
      setFiltro({
        ...filtro,
        [event.target.name]: vendedorId,
      });
    }
  };

  const enviarDatos = (event) => {
    event.preventDefault();
    dispatch(requestPresupuestosSearch(filtro));
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const stylePopup = {
    padding: "15px",
    marginTop: "30px",
    marginBottom: "30px",
    marginLeft: "20px",
    marginRight: "20px",
  };

  const btnConfirmar = {
    background: colores.rojo,
  };

  const formik = useFormik({
    initialValues: {
      selectedClient: "",
    },
    validationSchema: Yup.object({
      selectedClient: Yup.string(),
    }),
    onSubmit: (data) => {
      const clienteVendedor = props?.VenClientes?.filter(
        (cliente) => cliente.id === data.selectedClient
      );
      window.localStorage.setItem(
        "selectedClient",
        JSON.stringify(clienteVendedor)
      );
      window.location.reload(false);
    },
  });

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  var minDate = "2015-01-01";

  return (
    <div
      style={{
        backgroundColor: colores.grisClaro,
        overflow: "hidden",
        clear: "both",
      }}
    >
      <div>
        <Dolar></Dolar>

        <div
          className="container-fluid"
          style={{
            width: "100%",
            overflow: "hidden",
            clear: "both",
            marginBottom: "1.85rem",
          }}
        >
          <div className="d-flex justify-content-center">
            <hr style={{ width: "90%", color: "black", marginTop: "0" }}></hr>
          </div>
          <Container
            maxWidth="w-100"
            style={{ paddingRight: "60px", paddingLeft: "60px" }}
          >
            <div
              className="d-flex justify-content-between flex-wrap align-items-center "
              style={{ marginBottom: "0.600rem", paddingRight: "3.5rem" }}
            >
              <h1 className="fw-bolder" style={{ fontSize: "bolder" }}>
                MIS SOLICITUDES
              </h1>
            </div>
          </Container>
        </div>

        <div
          className="container-fluid mx-0 px-0 w-100"
          style={{ margin: "2rem" }}
        >
          <div
            className="container-fluid"
            style={{
              backgroundColor: "white",
              width: "100%",
              overflow: "hidden",
              clear: "both",
              marginBottom: "1.85rem",
              paddingLeft: "76px",
              paddingRight: "76px",
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ margin: "2rem 0" }}
            >
              <div>
                <h2>FILTROS</h2>
              </div>
              {myUser?.tipoRol === 2 ? (
                <div>
                  <Dialog
                    className="container-fluid"
                    open={open}
                    onClose={handleClose}
                  >
                    <Grid display="flex" justifyContent="flex-end">
                      <Button
                        style={{ border: "none", color: "black" }}
                        onClick={handleClose}
                      >
                        X
                      </Button>
                    </Grid>
                    <DialogContent style={stylePopup} align="center">
                      <form onSubmit={formik.handleSubmit}>
                        <select
                          onChange={formik.handleChange}
                          name="selectedClient"
                          className="form-select form-select-lg mb-3"
                          aria-label=".form-select-lg example"
                        >
                          <option value="" selected>
                            Seleccionar cliente
                          </option>
                          {props?.VenClientes?.map((cliente) => (
                            <option key={cliente.usuarioId} value={cliente.id}>
                              {cliente.nombreFantasia}
                            </option>
                          ))}
                        </select>
                        <Button
                          type="submit"
                          style={btnConfirmar}
                          class="btn btn-danger px-5 mt-4"
                          onClick={handleClose}
                        >
                          Confirmar
                        </Button>
                      </form>
                    </DialogContent>
                  </Dialog>
                  <Button
                    type="button"
                    style={{ background: colores.rojo }}
                    class="btn btn-danger"
                    onClick={handleOpen}
                  >
                    Nuevo Presupuesto
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>

            <form
              className="row row-cols-1 row-cols-sm-2 row-cols-md-3"
              onSubmit={enviarDatos}
              style={{ margin: "1rem 0.2rem", padding: 0 }}
            >
              {myUser?.tipoRol === 1 ? (
                <>
                  <div>
                    <label
                      htmlFor="vendedorId"
                      className="form-label"
                      style={{ fontWeight: 600, fontSize: "0.938rem" }}
                    >
                      Vendedor
                    </label>
                    <div class="input-group mb-3">
                      <select
                        name="vendedorId"
                        className="form-select"
                        id="vendedorId"
                        style={{ borderColor: "black" }}
                        onChange={handleInputChangeVendedor}
                      >
                        <option selected>Selecciona...</option>
                        {props?.vendedores?.map((vendedor) => (
                          <option key={vendedor.id} value={vendedor.id}>
                            {vendedor.apellidoNombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="clienteId"
                      className="form-label"
                      style={{ fontWeight: 600, fontSize: "0.938rem" }}
                    >
                      Cliente
                    </label>
                    <div class="input-group mb-3">
                      <select
                        name="clienteId"
                        className="form-select"
                        id="clienteId"
                        style={{ borderColor: "black" }}
                        onChange={handleInputChange}
                      >
                        <option selected value={clienteSelected}>
                          Selecciona...
                        </option>
                        {clienteSelected?.map((cliente) => (
                          <option key={cliente.id} value={cliente.id}>
                            {cliente.razonSocial}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="nroOperacion"
                      className="form-label"
                      style={{ fontWeight: 600, fontSize: "0.938rem" }}
                    >
                      Nro operación (ID)
                    </label>
                    <input
                      name="nroOperacion"
                      className="form-control"
                      id="nroOperacion"
                      style={{ borderColor: "black" }}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-6">
                        <label
                          htmlFor="desde"
                          className="form-label"
                          style={{ fontWeight: 600, fontSize: "0.938rem" }}
                        >
                          Desde
                        </label>
                        <input
                          type="date"
                          name="desde"
                          min={minDate}
                          max={today}
                          onKeyDown={(e) => e.preventDefault()}
                          className="form-control"
                          id="desde"
                          style={{ borderColor: "black" }}
                          onChange={handleInputChange}
                        ></input>
                      </div>
                      <div className="col-6">
                        <label
                          htmlFor="hasta"
                          className="form-label"
                          style={{ fontWeight: 600, fontSize: "0.938rem" }}
                        >
                          Até
                        </label>
                        <input
                          type="date"
                          name="hasta"
                          min={minDate}
                          max={today}
                          onKeyDown={(e) => e.preventDefault()}
                          className="form-control"
                          id="hasta"
                          style={{ borderColor: "black" }}
                          onChange={handleInputChange}
                        ></input>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {myUser?.tipoRol === 2 ? (
                <div className="d-flex align-items-end">
                  <div className="col-12">
                    <label
                      htmlFor="clienteId"
                      className="form-label"
                      style={{ fontWeight: 600, fontSize: "0.938rem" }}
                    >
                      Cliente
                    </label>
                    <div class="input-group">
                      <select
                        name="clienteId"
                        className="form-select"
                        id="clienteId"
                        style={{ borderColor: "black" }}
                        onChange={handleInputChange}
                      >
                        <option selected value={clienteSelected}>
                          Selecciona...
                        </option>
                        {clienteSelected?.map((cliente) => (
                          <option key={cliente.id} value={cliente.id}>
                            {cliente.razonSocial}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {myUser?.tipoRol === 2 || myUser?.tipoRol === 3 ? (
                <>
                  <div className="d-flex align-items-end">
                    <div className="row">
                      <div className="col-4">
                        <TextField
                          id="nroOperacion"
                          value={nroOperacion}
                          name="nroOperacion"
                          onChange={handleNroOperacionToChange}
                          label="Nro operación (ID)"
                          variant="outlined"
                        />
                      </div>
                      <div className="col-4">
                        <TextField
                          id="presupuesto"
                          value={presupuesto}
                          name="presupuesto"
                          onChange={handlePresupuestoToChange}
                          label="Presupuesto"
                          variant="outlined"
                        />
                      </div>
                      <div className="col-4">
                        <TextField
                          id="pedido"
                          value={pedido}
                          name="pedido"
                          onChange={handlePedidoToChange}
                          label="Pedido"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <div className="row">
                      <div className="col-6">
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <LocalizationProvider
                              adapterLocale="es"
                              dateAdapter={AdapterDayjs}
                            >
                              <MobileDatePicker
                                label="Desde"
                                inputFormat="DD/MM/YYYY"
                                value={dateFrom}
                                onChange={handleDateFromChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(errorUI?.date)}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </div>
                      <div className="col-6">
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <LocalizationProvider
                              adapterLocale="es"
                              dateAdapter={AdapterDayjs}
                            >
                              <MobileDatePicker
                                label="Até"
                                inputFormat="DD/MM/YYYY"
                                value={dateTo}
                                onChange={handleDateToChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={Boolean(errorUI?.date)}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-end">
                    <button
                      type="submit"
                      style={{ background: colores.rojo, color: "white" }}
                      class="btn"
                    >
                      Buscar
                    </button>
                    <button
                      style={{
                        background: colores.white,
                        color: "red",
                        borderColor: "red",
                        marginLeft: "2vh",
                      }}
                      class="btn"
                      onClick={handleClean}
                    >
                      Limpar
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}
            </form>

            <div className="container-fluid px-0">
              <div>
                {/* {props.presupuestos?.$values[0]?.filtro ? 
                <div className='container-fluid w-50  d-flex justify-content-center'>
                  <div className="alert alert-warning d-flex justify-content-center" role="alert">
                    Presupuesto no encontrado
                  </div>
                </div>
                :
                ""
              } */}
                {props?.loading ? (
                  <div className="container-fluid w-50  d-flex justify-content-center">
                    <h4>Carregando...</h4>
                  </div>
                ) : (
                  ""
                )}
                {
                  <div className="d-flex flex-wrap">
                    {props.presupuestos?.$values?.map((pedido) => (
                      <Pedido key={pedido.id} data={pedido} />
                    ))}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    presupuestos: state.presupuestos.presupuestos,
    loading: state.presupuestos.loading,
    VenClientes: state.presupuestos.VenClientes,
    clientes: state.clientes.clientes,
    vendedores: state.vendedor.vendedores,
  }),
  (dispatch) => ({
    requestPresupuestoSearch: (value) =>
      dispatch(requestPresupuestosSearch(value)),
    requestPresupuestos: (clienteId) => dispatch(requestPresupuestos(clienteId)),
    requestFetchClientes: (id) => dispatch(requestFetchClientes(id)),
    requestVendedores: () => dispatch(requestVendedores()),
  })
)(MisPresupuestos);
