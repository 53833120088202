import './timeline.css';
import Tooltip from '@mui/material/Tooltip';

const TimelineComponent = (props) => {
 
    const estados = [{"Id": 0, "Titulo":"PENDENTE"}, {"Id": 1 , "Titulo":"EM ANALISE"},{"Id": 2, "Titulo":"EM ANDAMENTO"},{"Id": 3, "Titulo":"AGUARDANDO"},
                    {"Id": 4, "Titulo":"DESCARTADO"},{"Id": 5, "Titulo":"RESOLVIDO"}];
    return (
        <>
            <div className='d-flex flex-wrap' style={{marginBottom: '30px', marginTop: '20px'}}>
                {
                    estados?.map(etapa => (
                        (etapa.Id > props?.data ?
                        <>
                            <Tooltip title={etapa.Titulo}>
                                <div className='timeline'>
                                    <div className="timeline-item-white">
                                        <div>{etapa.Id}</div>
                                    </div>
                                </div> 
                            </Tooltip>
                            {(etapa.Id < estados.length-1 ?
                            <>
                                <div className='line'> 
                                </div>    
                            </>
                            :
                            ""
                            )}   
                        </>
                        :
                        <>
                            <Tooltip title={etapa.Titulo}>
                                <div className='timeline'>
                                    <div className="timeline-item">
                                        <div>{etapa.Id}</div>
                                    </div>
                                </div>  
                            </Tooltip>
                            {(etapa.Id < estados.length-1 ?
                            <>
                                <div className='line'> 
                                </div>    
                            </>
                            :
                            ""
                            )}  
                        </>
                        )
                    ))
                }
            </div>
        </>
    );
};

export default TimelineComponent;